import React from "react";
import Layout from "../../components/layout/Layout";
import SEO from "../../components/SEO";
import Header from "../../components/layout/Header.js";
import SiteTitleQuery from "../../components/SiteTitleQuery";
import FieldworkImage from "../../images/inHouseImages/fieldworkImage.jpg";
import ProfileCard from "../../components/ProfileCard.js";
import Simon from "../../images/profiles/profileSimonB.jpg";

function Fieldwork() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title="Fältarbete"
            keywords={[`inmätning`, `fotografering`, `fotodokumentation`, `360-fotografering`, `GNSS`, `totalstation`, `fastighetsgränser`, `typografisk`, `drönare`,]}
          />
          <Header data={data} />

          <div className="container mx-auto flex flex-col md:flex-row items-center my-8 md:my-10 justify-center">
            <div className="sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-3xl rounded overflow-hidden shadow">
              <img className="w-full" src={FieldworkImage} alt="Totalstation" title="Totalstation"></img>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Fältarbete | 360º</div>
                <p className="text-gray-700 text-base">
                  Vi utför inmätningar och fotograferingar i fält som grund för
                  ritningar eller som kompletterande underlag skräddarsydda för
                  kundens behov.
                  Inmätning sker med GNSS (GPS) eller Totalstation, vilket ger
                  oss möjlighet att kunna få ut koordinater även under tak.
                  <br></br><br></br>
                  För dig som kund innebär detta t.ex. en smidigare byggprocess
                  eftersom vi kan få ut exakta mått till fastighetsgränser och
                  närliggande byggnader.
                  <br></br>
                  <br></br>
                  Som komplement till vanlig fotodokumentation finns även
                  tjänsten 360º-Fotografering där man får en högupplöst
                  helhetsbild av platsen och med enkelhet kan navigera runt till
                  olika punkter.
                  <br></br>
                  <br></br>
                  Vi tillhandahåller även fotografering och topografisk scanning med hjälp av drönare. 
                </p>
              </div>
              <ProfileCard
                profile={Simon}
                name="Simon Blomsterlund"
                email="sb@psksyd.com"
              />
            </div>
          </div>
        </Layout>
      )}
    />
  );
}

export default Fieldwork;
