import React from "react";

export default ({ profile, name, email }) => (
  <div className="flex items-center mt-10 p-6">
    <img
      className="w-16 h-16 rounded-full mr-4"
      src={profile}
      alt="profile picture"
    />
    <div className="text-sm">
      <p className="text-gray-900 leading-none">{name}</p>
      <a className="text-gray-600" href={`mailto:${email}`}>{email}</a>
    </div>
  </div>
);
